import { createTheme } from '@material-ui/core'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    opaque: Palette['primary']
    alerts: Palette['primary']
    black: Palette['primary']
    check: Palette['primary']
    dividers: Palette['primary']
    loading: Palette['primary']
    textConstrast: Palette['primary']
    status: Palette['primary']
    bg: Palette['primary']
    error: Palette['primary']
  }
  interface PaletteOptions {
    opaque: PaletteOptions['primary']
    alerts: PaletteOptions['primary']
    black: PaletteOptions['primary']
    check: PaletteOptions['primary']
    dividers: PaletteOptions['primary']
    loading: PaletteOptions['primary']
    textConstrast: PaletteOptions['primary']
    status: PaletteOptions['primary']
    bg: PaletteOptions['primary']
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: ({
    primary: {
      main: '#52607b',
      dark: '#475672',
      light: '#6D7990',
    },
    secondary: {
      main: '#F7F8F9',
      dark: '#00A2ED',
    },
    opaque: {
      main: '#EFF4FA',
      dark: '#374762',
    },
    black: {
      main: '#252631',
    },
    check: {
      main: '#00B995',
      light: '#98A9BC',
    },
    dividers: {
      main: '#C3CEDB',
      light: '#EBEBEB',
    },
    alerts: {
      main: '#EB8456',
      dark: '#eb6056',
      light: '#CCCC00',
    },
    loading: {
      main: '#00B995',
    },
    textConstrast: {
      main: '#fff',
      dark: '381E1C',
    },
    status: {
      main: '#00B995',
      light: '#1AA2ED',
      dark: '#F54242',
      contrastText: '#F2d600',
    },
    bg: {
      main: '#fff',
      light: '#FAFAFA',
    },
    error: {
      main: '#f44336',
    },
  } as unknown) as PaletteOptions,
})
