import { Dispatch } from 'redux'

import { camelize } from 'casing'
import { AxiosError, AxiosResponse } from 'axios'
import { update as updateAlert } from '../appAlert/actions'
import {
  DefaultErrorOnFetchData,
  DefaultErrorOnSubmitData,
  DefaultSuccesrOnSubmitData,
} from '../../utils/messageConstants'
import { getErrorMessages } from '../../utils/error'
import { getCloudApiManager } from './getCloudApiManager'
import { CloudType } from '../types'
export type DataType = 'integer' | 'string' | 'enum' | 'multi_enum'
export type ValidationType = 'email' | 'phone' | 'url'

export interface FormMetaDataContent {
  label: string
  value: string
  description: string
  validationType: string
  dataType: string
  isRequired: boolean
  maxLength?: number
  minLength?: number
}

export interface FormMetaData {
  logoUrl: FormMetaDataContent
  buttonColor: FormMetaDataContent
  bgColor: FormMetaDataContent
  bgImageUrl: FormMetaDataContent
  headingMessage: FormMetaDataContent
  redirectUrl: FormMetaDataContent
  redirectSeconds: FormMetaDataContent
  existingBuyerRedirectUrl: FormMetaDataContent
  existingBuyerRedirectSeconds: FormMetaDataContent
  existingBuyerMessage: FormMetaDataContent
  submissionSuccessMessage: FormMetaDataContent
  privacyPolicyText: FormMetaDataContent
  privacyPolicyLink: FormMetaDataContent
  productName?: string
  companyName?: FormMetaDataContent
  buttonTextColor: FormMetaDataContent
}

export interface FormFields {
  title: string
  humanReadableKey: string
  placeHolder: string
  id: string
  isRequired: boolean
  isCustom: boolean
  defaultValue: string
  dataType: DataType
  validationType: ValidationType
  displayOrder: number
  maxLength?: number //only applies, if type=string
  minLength?: number //only applies, if type=string
  options?: unknown // this will have value only when data_type=enum or multi_enum
}
export interface UserCollectionFormState {
  collectionData?: UserCollectionForm
  loading?: boolean
  loadingSubmit?: boolean
  submitted?: boolean
  trackId?: string
  marketplaceUrl?: string
  errMess: unknown
}

export interface Subscription {
  offerType: string
  customerIdentifier: string
  subscriptionName: string
  autoRenew: boolean
  isFreeTrail: boolean
  termUnit: string
  planId: string
  sellerId: string
  buyerId: string
  id: string
  startDate: string
  endDate: string
  createdAt?: string
  updatedAt?: string
  productId?: string
  status?: string
  quantity?: string
}

export interface Dimensions {
  id: string
  azureDimensionId?: string
  pricePerUnit: number
  unitOfMeasure: string
  displayName: string
  units: string
  planId?: string
}

export interface Buyer {
  id: string
  status: string
  displayName: string
  email: string
  azureBuyerId?: string
  userPrincipalName?: string
}

export interface UserCollectionForm {
  productName?: string
  companyName?: string
  marketplaceUrl: string
  infoCollectionFormMetaData: FormMetaData
  formFields: FormFields[]
  subscription?: Subscription
  dimensions: Dimensions[]
  planName?: string
  tcv?: number
  buyer?: Buyer
  submissionSuccessMessage?: string
  redirectUrl?: string
  redirectSeconds?: string
  existingBuyerMessage?: string
}

export enum userCollectionFormTypes {
  GET_USER_COLLECTION_FORM_DATA = 'GET_USER_COLLECTION_FORM_DATA',
  GET_USER_COLLECTION_FORM_ERROR = 'GET_USER_COLLECTION_FORM_ERROR',
  USER_COLLECTION_FORM_LOADING = 'USER_COLLECTION_FORM_LOADING',
  USER_COLLECTION_FORM_LOADING_SUBMIT = 'USER_COLLECTION_FORM_LOADING_SUBMIT',
  USER_COLLECTION_FORM_UPDATE_SUBMIT = 'USER_COLLECTION_FORM_UPDATE_SUBMIT',
  USER_COLLECTION_FORM_UPDATE_TRACK_ID = 'USER_COLLECTION_FORM_UPDATE_TRACK_ID',
}

export const setCollectionFormData = (data: unknown) => ({
  type: userCollectionFormTypes.GET_USER_COLLECTION_FORM_DATA,
  payload: camelize(data),
})

export const setCollectionFormDataLoading = (loading: boolean) => ({
  type: userCollectionFormTypes.USER_COLLECTION_FORM_LOADING,
  payload: loading,
})

export const setCollectionFormDataLoadingSubmit = (loading: boolean) => ({
  type: userCollectionFormTypes.USER_COLLECTION_FORM_LOADING_SUBMIT,
  payload: loading,
})

export const updateSubmitted = (loading: boolean) => ({
  type: userCollectionFormTypes.USER_COLLECTION_FORM_UPDATE_SUBMIT,
  payload: loading,
})
export const updateTrackId = (trackId: unknown, marketplaceUrl?: string) => ({
  type: userCollectionFormTypes.USER_COLLECTION_FORM_UPDATE_TRACK_ID,
  payload: { trackId: trackId, marketplaceUrl: marketplaceUrl },
})

export const getUserCollectionData = (
  buyerId: string,
  cloudProvider: CloudType,
  subscriptionId: string,
  getBuyerDetails: boolean
) => async (dispatch: Dispatch) => {
  dispatch(setCollectionFormDataLoading(true))
  try {
    const CloudApiManager = getCloudApiManager({
      cloudType: cloudProvider,
      subscriptionId,
    })
    const { data } = await CloudApiManager.fetchCustomerCollectionData(
      buyerId,
      cloudProvider,
      getBuyerDetails
    )

    await dispatch(setCollectionFormData(data))
  } catch (error: unknown) {
    const responseData = (error as AxiosError).response?.data
    await dispatch(
      updateTrackId(responseData?.track_id, responseData?.marketplace_url)
    )
    await dispatch(
      setErrorMess(responseData.errors?.join(', ') || DefaultErrorOnFetchData)
    )
  } finally {
    await dispatch(setCollectionFormDataLoading(false))
  }
}
export const submitUserCollectionData = (
  data: unknown,
  buyerId: string,
  cloudProvider: CloudType,
  subscriptionId: string
) => async (dispatch: Dispatch) => {
  await dispatch(
    updateAlert({
      message: undefined,
      messageType: 'NONE',
    })
  )
  dispatch(setCollectionFormDataLoadingSubmit(true))
  try {
    const CloudApiManager = getCloudApiManager({
      cloudType: cloudProvider,
      subscriptionId,
    })
    await CloudApiManager.submitCustomerCollectionData(
      data,
      buyerId,
      cloudProvider
    )
    await dispatch(updateSubmitted(true))
    await dispatch(
      updateAlert({
        message: DefaultSuccesrOnSubmitData,
        messageType: 'INFO',
      })
    )
  } catch (error: unknown) {
    await dispatch(updateSubmitted(false))
    const errMess = getErrorMessages([DefaultErrorOnSubmitData])(
      error as AxiosResponse<ErrorResponse>
    )
    await dispatch(
      updateAlert({
        message: errMess,
        messageType: 'ERROR',
      })
    )
  } finally {
    await dispatch(setCollectionFormDataLoadingSubmit(false))
  }
}
export const setErrorMess = (err: unknown) => ({
  type: userCollectionFormTypes.GET_USER_COLLECTION_FORM_ERROR,
  payload: err,
})
