import { combineReducers } from 'redux'
import {
  reducer as counterReducer,
  CounterState,
} from '../modules/counter/reducer'

import { reducer as collectionFormReducer } from '../modules/CustomerCollectionData/reducer'
import { UserCollectionFormState } from '../modules/CustomerCollectionData/action'
import {
  reducer as AlertReducer,
  AppAlertState,
} from '../modules/appAlert/reducer'

// NOTE: When adding more reducers with state, just add them here with intersections
// https://stackoverflow.com/a/27326935
// AppState = CounterState & AuthState & ScrollState
export type AppState = {
  counter: CounterState
  collectionForm: UserCollectionFormState
  appAlert: AppAlertState
}

export const rootReducer = combineReducers({
  counter: counterReducer,
  collectionForm: collectionFormReducer,
  appAlert: AlertReducer,
})
