import { CloudType } from '../types'
import {
  fetchCustomerCollectionAzure,
  fetchCustomerCollectionData,
  submitCustomerCollectionData,
  submitCustomerCollectionDataAzure,
} from '../../api/index'
import { AxiosResponse } from 'axios'
import { UserCollectionForm } from './action'

export type CloudApiManager = {
  fetchCustomerCollectionData: (
    buyerId: string,
    cloudProvider: string,
    getBuyerDetails: boolean
  ) => Promise<AxiosResponse<UserCollectionForm>>
  submitCustomerCollectionData: (
    data: unknown,
    buyerId: string,
    cloudProvider: string
  ) => void
}

type AzureCloudApiManagerParams = {
  cloudType: CloudType.AZURE
  subscriptionId: string
}

type AWSCloudApiManagerParams = {
  cloudType: CloudType.AWS
}

type GCPCloudApiManagerParams = {
  cloudType: CloudType.GCP
}

export const getCloudApiManager = (
  params:
    | AzureCloudApiManagerParams
    | AWSCloudApiManagerParams
    | GCPCloudApiManagerParams
): CloudApiManager => {
  switch (params.cloudType) {
    case CloudType.AZURE: {
      const { subscriptionId = '' } = params
      return {
        fetchCustomerCollectionData: (buyerId: string) =>
          fetchCustomerCollectionAzure(buyerId, subscriptionId),
        submitCustomerCollectionData: (data: unknown, buyerId: string) =>
          submitCustomerCollectionDataAzure(data, buyerId, subscriptionId),
      }
    }
    default: {
      return {
        fetchCustomerCollectionData,
        submitCustomerCollectionData,
      }
    }
  }
}
