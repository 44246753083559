import { AppAlertState } from './reducer'
export enum AppAlertActionTypes {
  UPDATE = 'UPDATE',
}

export type ErrorActions = {
  type: AppAlertActionTypes.UPDATE
}

export const update = (error: AppAlertState) => ({
  type: AppAlertActionTypes.UPDATE,
  error,
})
