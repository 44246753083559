import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SetupWorkerApi } from 'msw'
import { mockServerEnabled } from './utils/constants'
import { newrelicErrLogger } from './utils/ErrorHandler'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(window as any).__webpack_nonce__ = process.env.REACT_APP_CSP_NONCE

declare global {
  interface Window {
    analytics: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newrelic: {
      noticeError: (e: Error, customObj?: Record<string, unknown>) => void
    }
    NREUM: {
      loader_config: unknown
      info: unknown
    }
  }
}

if (mockServerEnabled) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { getWorker } = require('./mocks/browser')
  getWorker()
    .then((worker: SetupWorkerApi) => {
      worker.start()
    })
    .catch((e: Error) => {
      console.error(e)
      newrelicErrLogger(e as Error, {
        message: e,
      })
    })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
