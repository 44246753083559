export enum CounterActionTypes {
  ADD = 'ADD',
  SUBSTRACT = 'SUBSTRACT',
}

export type CounterActions =
  | {
      type: CounterActionTypes.ADD
    }
  | {
      type: CounterActionTypes.SUBSTRACT
    }

export const add = () => ({
  type: CounterActionTypes.ADD,
})

export const substract = () => ({
  type: CounterActionTypes.SUBSTRACT,
})
