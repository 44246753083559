import { Reducer, Action } from 'redux'
import { CounterActionTypes } from './actions'

export type CounterState = number

export const reducer: Reducer<CounterState, Action<CounterActionTypes>> = (
  state = 0,
  action
) => {
  switch (action.type) {
    case CounterActionTypes.ADD: {
      return state + 1
    }
    case CounterActionTypes.SUBSTRACT: {
      return state - 1
    }
    default: {
      return state
    }
  }
}
