import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { theme } from './theme'
import { Provider } from 'react-redux'
import { store } from './store'
import { AppRoutings } from './components/AppRoutings'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div style={{ height: '100%' }}>
          <AppRoutings />
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default App
