import {
  UserCollectionForm,
  UserCollectionFormState,
  userCollectionFormTypes,
} from './action'
import { orderBy } from 'lodash'

const initialState: UserCollectionFormState = {
  collectionData: undefined,
  loading: undefined,
  loadingSubmit: undefined,
  submitted: undefined,
  trackId: undefined,
  marketplaceUrl: undefined,
  errMess: null,
}
type Action = { type: userCollectionFormTypes; payload: unknown }
export const reducer = (
  state: UserCollectionFormState = initialState,
  action: Action
) => {
  switch (action.type) {
    case userCollectionFormTypes.GET_USER_COLLECTION_FORM_DATA: {
      const userCollectionForm = action.payload as UserCollectionForm

      userCollectionForm.formFields = orderBy(userCollectionForm.formFields, [
        'displayOrder',
      ])
      return { ...state, collectionData: userCollectionForm, errMess: null }
    }
    case userCollectionFormTypes.GET_USER_COLLECTION_FORM_ERROR: {
      return { ...state, collectionData: null, errMess: action.payload }
    }
    case userCollectionFormTypes.USER_COLLECTION_FORM_LOADING: {
      return { ...state, loading: action.payload }
    }
    case userCollectionFormTypes.USER_COLLECTION_FORM_LOADING_SUBMIT: {
      return { ...state, loadingSubmit: action.payload }
    }
    case userCollectionFormTypes.USER_COLLECTION_FORM_UPDATE_SUBMIT: {
      return { ...state, submitted: action.payload }
    }
    case userCollectionFormTypes.USER_COLLECTION_FORM_UPDATE_TRACK_ID: {
      const { trackId, marketplaceUrl } = action.payload as {
        trackId: string
        marketplaceUrl: string
      }
      return { ...state, trackId: trackId, marketplaceUrl: marketplaceUrl }
    }
    default:
      return state
  }
}
