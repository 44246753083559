/* istanbul ignore file */
import { ThemeProvider } from '@material-ui/core'
import { labravDefaultThemeV4, LoadingLogo } from 'labrav-react-components'
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

const CustomerCollectionForm = React.lazy(
  () => import('./CustomerCollectionForm/CustomerCollectionForm')
)
const ErrorPage = React.lazy(() => import('./ErrorPage/ErrorPage'))
const GifCardRedeem = React.lazy(() => import('./GiftCardRedeem'))

export const AppRoutings: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route
            path="/buyer_form"
            render={() => (
              <Suspense fallback={<LoadingLogo />}>
                <CustomerCollectionForm />
              </Suspense>
            )}
          />
          <Route
            path="/error"
            render={() => (
              <Suspense fallback={<LoadingLogo />}>
                <ErrorPage />
              </Suspense>
            )}
          />
          <Route
            path="/gift-card"
            render={() => (
              <Suspense fallback={<LoadingLogo />}>
                <ThemeProvider theme={labravDefaultThemeV4}>
                  <Helmet>
                    <title>Redeem Amazon Gift Card</title>
                    <meta
                      name="description"
                      content="Sign up as a beta user of Labra FlyOut and get a 2-month free subscription along with an  Amazon Gift Card"
                    />
                  </Helmet>

                  <GifCardRedeem />
                </ThemeProvider>
              </Suspense>
            )}
          />
          <Redirect from="*" to="/error" />
        </Switch>
      </Router>
    </>
  )
}
