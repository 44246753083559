import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import { applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Middleware, Dispatch, Action } from 'redux'
import { isProduction } from '../utils/environment'

export const getMiddlewares = () => {
  const productionMiddlewares = ([thunkMiddleware] as unknown) as Array<
    Middleware<unknown, unknown, Dispatch<Action>>
  >

  if (isProduction()) {
    return applyMiddleware(...productionMiddlewares)
  } else {
    const devMiddlewares = [logger]
    return composeWithDevTools(
      applyMiddleware(...productionMiddlewares.concat(devMiddlewares))
    )
  }
}
